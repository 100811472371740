<template>
  <div id="equivalences">
    <h1>Bienvenue Equivalences</h1>
    <p>
      Les opérateurs ont chacun leurs propre noms pour les différentes destinations etc.. <br>
      Nous avons défini la liste des valeurs utilisées par Colombia et cette page permet d'indiquer des équivalences entre le nom donné par l'opérateur et celui de Colombia
    </p>
    <FormInsertEqu
      :jwt="this.jwt"
    ></FormInsertEqu>
    <h2>Valeurs en erreur</h2>
    <div id="equivalences-main">
      <div id="destination">
        <DataTableAPI
          :jwt="this.jwt"
          :headerFields="this.headerFieldsDestination"
          :apiUrl="this.$apiBaseUrl + 'rpc/func_getbaddestination'"
          :defaultSort="{sort: 'asc', sortField: 'dest'}"
        >
        </DataTableAPI>
      </div>
      <div id="destination-categorie">
        <DataTableAPI
          :jwt="this.jwt"
          :headerFields="this.headerFieldsDestinationCategorie"
          :apiUrl="this.$apiBaseUrl + 'rpc/func_getbaddestinationcategorie'"
          :defaultSort="{sort: 'asc', sortField: 'dest_categorie'}"
        >
      </DataTableAPI>
      </div>
      <div id="numero-facture">
        <DataTableAPI
          :jwt="this.jwt"
          :headerFields="this.headerFieldsNumeroFacture"
          :apiUrl="this.$apiBaseUrl + 'rpc/func_getbadnumerofacture'"
          :defaultSort="{sort: 'asc', sortField: 'numero_facture'}"
        >
      </DataTableAPI>
      </div>
      <div id="service">
        <DataTableAPI
          :jwt="this.jwt"
          :headerFields="this.headerFieldsService"
          :apiUrl="this.$apiBaseUrl + 'rpc/func_getbadservice'"
          :defaultSort="{sort: 'asc', sortField: 'service'}"
        >
        </DataTableAPI>
      </div>
    </div>
    <FormBadToGlobal
      :jwt="this.jwt"
    ></FormBadToGlobal>
    <h2>CDR BAD</h2>
    <DataTableAPI
        :jwt="this.jwt"
        :headerFields="this.headerFieldsCdrBad"
        :apiUrl="this.$apiBaseUrl + 'bad'"
        :defaultSort="{sort: 'desc', sortField: 'date_appel'}"
      >
      </DataTableAPI>
  </div>
</template>

<script>
import DataTableAPI from '@/components/DataTableAPI.vue'
import FormInsertEqu from '@/components/FormInsertEqu.vue'
import FormBadToGlobal from '@/components/FormBadToGlobal.vue'

let headerFieldsDestination = [
  {
    name: 'dest',
    label: 'Destination',
    sortable: true
  }
];

let headerFieldsDestinationCategorie = [
  {
    name: 'dest_categorie',
    label: 'Categorie de destination',
    sortable: true
  }
];

let headerFieldsNumeroFacture = [
  {
    name: 'numero_facture',
    label: 'Numero',
    sortable: true
  }
];

let headerFieldsService = [
  {
    name: 'service',
    label: 'Service',
    sortable: true
  }
];

let headerFieldsCdrBad = [
  {
    name: 'callid',
    label: 'Id appel',
    sortable: false
  },
  {
    name: 'import_id',
    label: 'Id import',
    sortable: true
  },
  {
    name: 'provider',
    label: 'Fournisseur',
    sortable: true
  },
  {
    name: 'date_appel',
    label: 'Date appel',
    sortable: true
  },
  {
    name: 'duree_appel',
    label: 'Duree',
    sortable: true
  },
  {
    name: 'donnee_mobile_o',
    label: 'Données mobiles (octet)',
    sortable: true
  },
  {
    name: 'nb_sms',
    label: 'SMS',
    sortable: true
  },
  {
    name: 'nb_mms',
    label: 'MMS',
    sortable: true
  },
  {
    name: 'numero_facture',
    label: 'Numéro facturé',
    sortable: true
  },
  {
    name: 'numero_appelant',
    label: 'Numéro appelant',
    sortable: true
  },
  {
    name: 'destination',
    label: 'Destination',
    sortable: true
  },
  {
    name: 'destination_categorie',
    label: 'Catégorie de destination',
    sortable: true
  },
  {
    name: 'prix_achat',
    label: 'Prix d\'achat',
    sortable: true
  },
  {
    name: 'cout_service_ht',
    label: 'Cout service HT',
    sortable: true
  },
  {
    name: 'code_client',
    label: 'Code client',
    sortable: true
  },
  {
    name: 'service',
    label: 'Service',
    sortable: true
  }
];

export default {
  name: 'Equivalences',
  components: {
    DataTableAPI,
    FormInsertEqu,
    FormBadToGlobal
  },
  data: function () {
    return {
      jwt: this.$jwt_temp,
      headerFieldsDestination: headerFieldsDestination,
      headerFieldsDestinationCategorie: headerFieldsDestinationCategorie,
      headerFieldsNumeroFacture: headerFieldsNumeroFacture,
      headerFieldsService: headerFieldsService,
      headerFieldsCdrBad: headerFieldsCdrBad
    }
  },
  methods: {
  },
  created() {
  }
}
</script>

<style scoped>
#equivalences-main {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

#equivalences-main > *:not(:last-child){
  margin-right: 30px;
}
</style>
