<template>
    <div id="DataTableAPI-container">
        <div class="header">
            <span>{{ totalItems }} resultats</span>
            <div v-if="searchable" id="search-container">
                <input @keyup.enter="searchText = input_searchValue" v-model="input_searchValue" type="text" name="search-text" id="input-search-text">
                <button @click="searchText = input_searchValue">Valider</button>
            </div>
        </div>
        <DataTable
            :header-fields="this.headerFields"
            :data="this.data || []"
            :sort="this.sort"
            :sort-field="this.sortField"
            @on-update="updateSort"
        >
            <Pagination
                slot="pagination"
                :page="this.currentPage"
                :total-items="this.totalItems"
                :items-per-page="this.itemsPerPage"
                @on-update="changePage"
            />
            <div class="items-per-page" slot="ItemsPerPage">
                <label>Items per page</label>
                <ItemsPerPageDropdown
                :list-items-per-page="this.listItemsPerPage"
                :items-per-page="this.itemsPerPage"
                @on-update="updateItemsPerPage"
                />
            </div>
            <div slot="linkToItem" slot-scope="props">
              <button type="button" @click="navigateTo(props)">Go to</button>
            </div>
        </DataTable>
    </div>
</template>


<script>
import axios from 'axios';
import { bus } from '../main';
import { DataTable, ItemsPerPageDropdown, Pagination } from "v-datatable-light";

export default {
    name: 'DataTableAPI',
    components: {
        DataTable,
        ItemsPerPageDropdown,
        Pagination
    },
    data: function() {
        return {
            datatableCss: {
                table: "table table-bordered table-hover table-striped table-center",
                th: "thead-th",
                thWrapper: "th-wrapper",
                thWrapperCheckboxes: "th-wrapper checkboxes",
                arrowsWrapper: "arrows-wrapper",
                arrowUp: "arrow up",
                arrowDown: "arrow down",
                footer: "footer"
            },
            paginationCss: {
                paginationItem: "pagination-item",
                moveFirstPage: "move-first-page",
                movePreviousPage: "move-previous-page",
                moveNextPage: "move-next-page",
                moveLastPage: "move-last-page",
                pageBtn: "page-btn"
            },
            itemsPerPageCss: {
                select: "item-per-page-dropdown"
            },
            data: [],
            sort: "",
            sortField: "",
            listItemsPerPage: [5, 10, 20, 50, 100],
            itemsPerPage: 20,
            currentPage: 1,
            totalItems: 0,
            input_searchValue: '',
            searchText: ''
        }
    },
    props: {
        jwt: String,
        apiUrl: String,
        apiArgs: {
            default: '',
            type: String
        },
        headerFields: Array,
        searchable: {
            default: false,
            type: Boolean
        },
        defaultSort: Object
    },
    methods: {
        refreshDataTable: function(args = '') {
            let offset = (this.currentPage - 1) * this.itemsPerPage;
            let URL = this.apiUrl + "?limit=" + this.itemsPerPage + "&offset=" + offset
            URL += this.sort && this.sortField ? "&order=" + this.sortField + "." + this.sort : '';
            URL += this.searchable && this.searchText ? "&in_text=" + this.searchText : '';
            URL += args ? args : '';
            URL += this.apiArgs ? this.apiArgs : '';
            axios
            .get(URL, {
                headers: {
                    Authorization: "Bearer " + this.jwt,
                    Prefer: 'count=exact' // Needed to return the total number of items in the table
                },
                withCredentials: true
            })
            .then( res => {
                this.data = res.data;
                // Set the number of items to calculate number of pages
                let totalItems = res.headers['content-range'].split('/')[1];
                this.totalItems = parseInt(totalItems) ? parseInt(totalItems) : 0;
            })
            .catch((e) => {
                if(!e.response) {
                    alert('Serveur inaccessible');
                    console.log('Serveur inaccessible');
                    return;
                }
                switch(e.response.status) {
                    case 400:
                        alert('Bad request (Erreur dans la requête)');
                        console.log('Bad request (Erreur dans la requête)');
                        break;
                    case 401:
                        alert('Unauthorized (JWT invalide)');
                        console.log('Unauthorized (JWT invalide)');
                        break;
                    case 403:
                        alert('Forbidden (Vous n\'avez pas les droits pour cette requête)');
                        console.log('Forbidden (Vous n\'avez pas les droits pour cette requête)');
                        break;
                    default:
                        alert(e.response.statusText);
                        console.log(e.response);
                }
            });
        },
        updateSort: function({sortField, sort}){
            this.sortField =  sortField;
            this.sort = sort;
            this.currentPage = 1;
            this.refreshDataTable();
        },
        updateItemsPerPage: function(itemsPerPage) {
            this.itemsPerPage = parseInt(itemsPerPage);
            this.currentPage = 1;
        },
        changePage: function(pageNumber) {
            this.currentPage = pageNumber;
        },
        navigateTo: function(props) {
            let linkField = this.headerFields.find(elem => elem.customElement === 'linkToItem');
            let data = {
                name: linkField.link,
                params: {
                    id: props.rowData.id
                }
            }
            console.log(data);
            this.$router.push(data);
        }
    },
    mounted() {
        if(this.defaultSort != undefined) {
            this.sort = this.defaultSort.sort;
            this.sortField = this.defaultSort.sortField;
        }
        this.refreshDataTable();
        bus.$on('refreshDatatable', this.refreshDataTable);
    },
    watch: {
        itemsPerPage: function() {
            this.refreshDataTable();
        },
        currentPage: function() {
            this.refreshDataTable();
        },
        searchText: function() {
            this.refreshDataTable();
        }
    }
}
</script>


<style scoped>
#DataTableAPI-container {
    width: fit-content;
    max-width: 100%;
    overflow-x: auto;
}

/* header */
.header {
    display: flex;
    justify-content: space-between;
}
</style>

<style>
/* Datatable CSS */
.v-datatable-light {
    border-collapse: collapse;
    max-width: fit-content;
}

.v-datatable-light .thead-th {
  cursor: pointer;
  color: var(--datatable-interactive-color);
  transition: color 0.15s ease-in-out;
  border: 1px solid #dee2e6;
  padding: 0.75em;
  white-space: nowrap;
}

.v-datatable-light .thead-th:hover {
  color: var(--datatable-interactive-color-hover);
}

.v-datatable-light .thead-th.no-sortable {
  cursor: default;
}
.v-datatable-light .thead-th.no-sortable:hover {
  color: var(--datatable-interactive-color);
}

.v-datatable-light .thead-th .th-wrapper {
  display: flex;
  width: 100%;
  height: 100%;
  font-weight: bold;
}

.v-datatable-light .tbody-td {
    border: 1px solid #dee2e6;
    padding: 0.5em;
}

.v-datatable-light .tbody-tr:nth-of-type(odd) {
    background-color: rgba(0,0,0,.05);
}

.v-datatable-light .thead-th .th-wrapper .arrows-wrapper {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  justify-content: space-between;
}

.v-datatable-light .thead-th .th-wrapper .arrows-wrapper.centralized {
  justify-content: center;
}

.v-datatable-light .arrow-up, .v-datatable-light .arrow-down {
  transition: color 0.15s ease-in-out;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
}

.v-datatable-light .arrow-up {
  border-bottom: 8px solid var(--datatable-interactive-color);
}

.v-datatable-light .arrow-up:hover {
  border-bottom: 8px solid var(--datatable-interactive-color-hover);
}

.v-datatable-light .arrow-down {
  border-top: 8px solid var(--datatable-interactive-color);
}

.v-datatable-light .arrow-down:hover {
  border-top: 8px solid var(--datatable-interactive-color-hover);
}

.v-datatable-light .footer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  /*width: 500px;*/
  padding: 0.5rem;
}
/* End Datatable CSS */

/* Pagination CSS */
.v-datatable-light-pagination {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0;
  padding: 0;
  /*width: 300px;*/
  height: 30px;
}

.v-datatable-light-pagination .pagination-item {
  width: 30px;
  margin-right: 5px;
  font-size: 16px;
  transition: color 0.15s ease-in-out;
}

.v-datatable-light-pagination .pagination-item.selected {
  color: var(--datatable-interactive-color-hover);
}

.v-datatable-light-pagination .pagination-item .page-btn {
  background-color: transparent;
  outline: none;
  border: none;
  color: var(--datatable-interactive-color);
  transition: color 0.15s ease-in-out;
}

.v-datatable-light-pagination .pagination-item .page-btn:hover {
  color: var(--datatable-interactive-color-hover);
  cursor: pointer;
}

.v-datatable-light-pagination .pagination-item .page-btn:disabled {
  cursor: not-allowed;
  box-shadow: none;
  opacity: 0.65;
}
/* END PAGINATION CSS */

/* ITEMS PER PAGE DROPDOWN CSS */
.v-datatable-light-items-per-page {
  background-color: transparent;
  min-height: 30px;
  border: 1px solid var(--datatable-interactive-color);
  border-radius: 5px;
  color: var(--datatable-interactive-color);
}
.v-datatable-light-items-per-page:hover {
  cursor: pointer;
}

.items-per-page {
    display: flex;
    align-items: center;
    color: var(--datatable-interactive-color);
}

.items-per-page label {
    margin: 0 15px;
    white-space: nowrap;
}
/* END ITEMS PER PAGE DROPDOWN CSS */
</style>