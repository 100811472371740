<template>
    <div id="form-container">
        <h3>Insérer un client</h3>
        <form @submit="submitForm" method="post" class="form-insert">
            <ul id="error-list" v-if="errors && errors.length > 0">
                <li v-for="error in errors" :key="error">{{ error }}</li>
            </ul>
            <span>
                <label for="code_client">Code client : <img src="@/assets/images/icon-info.svg" alt="Hover info" title="Essayer d'utiliser le code client Dolibarr" class="icon-info"></label>
                <input v-model="form_codeClient" type="text" name="code_client" id="input_code_client" maxlength="50" required/>
            </span>
            <span>
                <label for="id_doli">Id dolibarr : <img src="@/assets/images/icon-info.svg" alt="Hover info" title="Sur la page du tiers dans l'url socid=xxxx" class="icon-info"></label>
                <input v-model="form_idDoli" type="number" name="id_doli" id="input_id_doli">
            </span>
            <span>
                <label for="description">Description : </label><br>
                <textarea v-model="form_description" name="description" id="input_description" cols="25" rows="2"></textarea>
            </span>
            <span>
                <input type="submit" id="submit" value="Valider">
                <p v-if="waiting">Waiting...</p>
            </span>
        </form>
    </div>
</template>

<script>
import axios from 'axios';
import { bus } from '../main';

export default {
    name: 'FormInsertClient',
    components: {
    },
    data: function() {
        return {
            form_codeClient: '',
            form_idDoli: '',
            form_description: '',
            errors: [],
            waiting: false,
            apiUrlInsert: this.$apiBaseUrl + 'rpc/func_insert_client'
        }
    },
    props: ['jwt'],
    methods: {
        addClient: async function(code_client, id_doli = null, description = '') {
            const URL = this.apiUrlInsert;
            const data = {
                in_code_client: code_client,
                in_id_doli: id_doli,
                in_description: description
            };
            let id_new = false;
            await axios
            .post(URL, data, {
                headers: {
                    Authorization: "Bearer " + this.jwt,
                }
            })
            .then( res => {
                id_new = res.data;
            })
            .catch((e) => {
                console.log(e);
            });
            return id_new;
        },
        submitForm: async function(e) {
            e.preventDefault();
            this.waiting = true;
            this.errors = [];
            
            // Check nom client
            if(this.form_codeClient.length > 2){
                console.log('check nom client OK: ' + this.form_codeClient);
            } else {
                this.errors.push('Nom client trop court');
            }
            // Check id doli
            let id_doli = parseInt(this.form_idDoli)
            if(!id_doli){
                id_doli = null;
            }
            // Check description
            let description = this.form_description;
            if(!description){
                description = null;
            }

            this.waiting = false;
            if(this.errors.length === 0) {
                let newId = await this.addClient(this.form_codeClient, id_doli, description);
                bus.$emit('refreshDatatable');
                if(newId) alert('Nouveau client bien inséré avec l\'id: ' + newId);
                else this.errors.push('Erreur d\'insertion, veuillez vérifier que le client n\'existe pas déjà');
            }
        }
    },
    mounted() {
    }
}
</script>



<style scoped>
#form-container {
    margin: 1rem;
}

#form-container h3 {
    margin-top: 0;
    margin-bottom: 0.5rem;
}

.form-insert {
    display: flex;
    flex-direction: column;
}

#error-list {
    color: rgb(252, 64, 31);
}
</style>