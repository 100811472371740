<template>
    <div id="form-container">
        <h3>Insérer un usager</h3>
        <form @submit="submitForm" method="post" class="form-insert">
            <ul id="error-list" v-if="errors && errors.length > 0">
                <li v-for="error in errors" :key="error">{{ error }}</li>
            </ul>
            <span>
                <label for="nom_client">Nom client : </label>
                <input v-model="form_nomClient" list="noms_clients" type="text" name="nom_client" id="input_nom_client" required/>
                <datalist id="noms_clients">
                    <option v-for="client in clients" :key="client.id">{{ client.code_client }}</option>
                </datalist>
            </span>
            <span>
                <label for="date_debut">Date de début : </label>
                <input v-model="form_dateDebut" type="date" name="date_debut" id="input_date_debut" required>
            </span>
            <span>
                <label for="date_fin">Date de fin : </label>
                <input v-model="form_dateFin" type="date" name="date_fin" id="input_date_fin" value="9999-01-01" required>
            </span>
            <span>
                <input type="submit" id="submit" value="Valider">
                <p v-if="waiting">Waiting...</p>
            </span>
        </form>
    </div>
</template>

<script>
import axios from 'axios';
import { bus } from '../main';
import { formatDate } from '@/lib/date_func.js'

export default {
    name: 'FormInsertNumeroUsager',
    components: {
    },
    data: function() {
        return {
            clients: [],
            form_nomClient: '',
            form_dateDebut: '',
            form_dateFin: '9999-01-01',
            errors: [],
            waiting: false,
            apiUrlClients: this.$apiBaseUrl + 'clients',
            apiUrlInsert: this.$apiBaseUrl + 'rpc/func_insert_usager'
        }
    },
    props: ['jwt', 'id_numero'],
    methods: {
        refreshListClients: function() {
            console.log(this.apiUrlClients);
            const URL = this.apiUrlClients + "?order=code_client.asc" + "&select=id,code_client";
            axios
            .get(URL, {
                headers: {
                    Authorization: "Bearer " + this.jwt,
                }
            })
            .then( res => {
                this.clients = res.data;
            })
            .catch((e) => console.log(e))
        },
        getIdClient: async function(name) {
            const URL = this.apiUrlClients + "?code_client=eq." + name + "&limit=1" + "&select=id";
            let id_client = false;
            await axios
            .get(URL, {
                headers: {
                    Authorization: "Bearer " + this.jwt,
                }
            })
            .then( res => {
                id_client = res.data[0].id;
            })
            .catch((e) => {
                console.log(e);
            });
            return id_client;
        },
        addUsager: async function(id_client, date_debut, date_fin) {
            const URL = this.apiUrlInsert;
            const data = {
                in_id_numero: parseInt(this.id_numero),
                in_id_client: id_client,
                in_date_debut: date_debut,
                in_date_fin: date_fin
            };
            console.log(data);
            let id_new = false;
            await axios
            .post(URL, data, {
                headers: {
                    Authorization: "Bearer " + this.jwt,
                }
            })
            .then( res => {
                id_new = res.data;
            })
            .catch((e) => {
                console.log(e);
            });
            return id_new;
        },
        submitForm: async function(e) {
            e.preventDefault();
            this.waiting = true;
            this.errors = [];
            
            // Check Client
            let id_client = await this.getIdClient(this.form_nomClient);
            if(id_client){
                console.log('check client OK: ' + id_client);
            } else {
                this.errors.push('Pas de client à ce nom');
            }
            // Check Date Debut
            let date_debut = new Date(this.form_dateDebut);
            if(date_debut instanceof Date && !isNaN(date_debut)){
                console.log('check Date Debut OK : ' + formatDate(date_debut));
            } else {
                this.errors.push('Date de début non valide');
            }
            // Check Date Fin
            let date_fin = new Date(this.form_dateFin);
            if(date_fin instanceof Date && !isNaN(date_fin)){
                console.log('check Date Fin OK : ' + formatDate(date_fin));
            } else {
                this.errors.push('Date de fin non valide');
            }

            this.waiting = false;
            if(this.errors.length === 0) {
                let newId = await this.addUsager(id_client, formatDate(date_debut), formatDate(date_fin));
                bus.$emit('refreshDatatable');
                if(newId) alert('Nouvel usager bien inséré avec l\'id: ' + newId);
                else this.errors.push('Erreur d\'insertion, veuillez vérifier que le numéro n\'est pas déjà utilisé à une date ultérieure');
            }
        }
    },
    mounted() {
        this.refreshListClients();
    }
}
</script>



<style scoped>
#form-container {
    margin: 1rem;
}

#form-container h3 {
    margin-top: 0;
    margin-bottom: 0.5rem;
}

.form-insert {
    display: flex;
    flex-direction: column;
}

#error-list {
    color: rgb(252, 64, 31);
}
</style>