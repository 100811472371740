<template>
  <div id="notfound">
    <h1>URL NOT FOUND</h1>
  </div>
</template>

<script>
export default {
  name: 'NotFound',
  components: {
  },
  data: function () {
    return {
    }
  },
  methods: {
  }
}
</script>

<style>
</style>
