<template>
    <div id="form-container">
        <h3>Insérer un nouveau numéro</h3>
        <form @submit="submitForm" method="post" class="form-insert-numero">
            <ul id="error-list" v-if="errors && errors.length > 0">
                <li v-for="error in errors" :key="error">{{ error }}</li>
            </ul>
            <span class="required">
                <label for="prefixe">Préfixe : </label>
                <input v-model="form_prefixe" type="text" name="prefixe" id="input_prefixe" maxlength="5" required>
            </span>
            <span class="required">
                <label for="numero">Numéro : </label>
                <input v-model="form_numero" type="text" name="numero" id="input_numero" required>
            </span>
            <span>
                <label for="showDetail">Insérer détails : </label>
                <input type="checkbox" name="showDetail" id="checkbox_showDetail" v-model="insertDetail">
            </span>
            <datalist id="noms_clients">
                <option v-for="client in clients" :key="client.id">{{ client.code_client }}</option>
            </datalist>
            <span v-if="insertDetail">
                <label for="nom_usager">Nom usager : </label>
                <input v-model="form_nomUsager" list="noms_clients" type="text" name="nom_usager" id="input_nom_usager"/>
            </span>
            <span v-if="insertDetail">
                <label for="nom_proprietaire">Nom propriétaire : </label>
                <input v-model="form_nomProprietaire" list="noms_clients" type="text" name="nom_proprietaire" id="input_nom_proprietaire"/>
            </span>
            <span v-if="insertDetail">
                <label for="nom_operateur">Nom opérateur : </label>
                <input v-model="form_nomOperateur" type="text" name="nom_operateur" id="input_nom_operateur"/>
            </span>
            <span v-if="insertDetail" class="required">
                <label for="date_debut">Date de début : </label>
                <input v-model="form_dateDebut" type="date" name="date_debut" id="input_date_debut" required>
            </span>
            <span v-if="insertDetail">
                <label for="date_fin">Date de fin d'usage: </label>
                <input v-model="form_dateFin" type="date" name="date_fin" id="input_date_fin" value="9999-01-01">
            </span>
            <span>
                <input type="submit" id="submit_numero" value="Valider">
                <p v-if="waiting">Waiting...</p>
            </span>
        </form>
    </div>
</template>

<script>
import axios from 'axios';
import { bus } from '../main';
import { formatDate } from '@/lib/date_func.js'

export default {
    name: 'FormInsertNumero',
    components: {
    },
    data: function() {
        return {
            clients: [],
            form_nomUsager: '',
            form_nomProprietaire: '',
            form_nomOperateur: '',
            form_prefixe: '',
            form_numero: '',
            form_dateDebut: '',
            form_dateFin: '9999-01-01',
            errors: [],
            waiting: false,
            insertDetail: false,
            apiUrlClients: this.$apiBaseUrl + 'clients'
        }
    },
    props: ['jwt'],
    methods: {
        refreshListClients: function() {
            const URL = this.apiUrlClients + "?order=code_client.asc" + "&select=id,code_client";
            axios
            .get(URL, {
                headers: {
                    Authorization: "Bearer " + this.jwt,
                }
            })
            .then( res => {
                this.clients = res.data;
            })
            .catch((e) => console.log(e))
        },
        getIdClient: async function(name) {
            const URL = this.apiUrlClients + "?code_client=eq." + name + "&limit=1" + "&select=id";
            let id_client = false;
            await axios
            .get(URL, {
                headers: {
                    Authorization: "Bearer " + this.jwt,
                }
            })
            .then( res => {
                id_client = res.data[0].id;
            })
            .catch((e) => {
                console.log(e);
            });
            return id_client;
        },
        addNumero: async function(prefixe, numero, id_usager, id_proprietaire, nom_operateur, date_debut, date_fin) {
            const URL = this.$apiBaseUrl + 'rpc/func_insert_numero';
            const data = {
                in_prefixe: prefixe,
                in_numero: numero,
            };
            if(id_usager) data.in_id_usager = id_usager;
            if(id_proprietaire) data.in_id_proprietaire = id_proprietaire;
            if(id_usager) data.in_id_usager = id_usager;
            if(nom_operateur) data.in_nom_operateur = nom_operateur;
            if(date_debut) data.in_date_debut = date_debut;
            if(date_fin) data.in_date_fin = date_fin;

            let id_newNumero = false;
            await axios
            .post(URL, data, {
                headers: {
                    Authorization: "Bearer " + this.jwt,
                }
            })
            .then( res => {
                id_newNumero = res.data;
            })
            .catch((e) => {
                console.log(e);
            });
            return id_newNumero;
        },
        submitForm: async function(e) {
            e.preventDefault();
            this.waiting = true;
            this.errors = [];
            
            // Check Prefixe
            if(/^[0-9]{1,3}$/.test(this.form_prefixe)) {
                console.log('check prefixe OK: ' + this.form_prefixe);
            } else {
                this.errors.push('Préfixe non valide');
            }
            // Check Numero
            let numero = this.form_numero.replace(/^0+/, '');
            if(/^[0-9]{1,9}$/.test(numero)){
                console.log('check numero OK: ' + numero);
            } else {
                this.errors.push('Numero non valide');
            }
            // # Optional
            let id_usager;
            let id_proprietaire;
            let date_debut;
            let date_fin;
            if(this.insertDetail) {
                // Check Usager
                if(this.form_nomUsager){
                    id_usager = await this.getIdClient(this.form_nomUsager);
                    if(id_usager){
                        console.log('check client OK: ' + id_usager);
                    } else {
                        this.errors.push('Usager: Pas de client à ce nom');
                    }
                }
                // Check Proprietaire
                if(this.form_nomProprietaire){
                    id_proprietaire = await this.getIdClient(this.form_nomProprietaire);
                    if(id_proprietaire){
                        console.log('check client OK: ' + id_proprietaire);
                    } else {
                        this.errors.push('Proprietaire: Pas de client à ce nom');
                    }
                }
                // Check Date Debut
                if(this.form_dateDebut) {
                    date_debut = new Date(this.form_dateDebut);
                    if(date_debut instanceof Date && !isNaN(date_debut)){
                        console.log('check Date Debut OK : ' + formatDate(date_debut));
                    } else {
                        this.errors.push('Date de début non valide');
                    }
                }
                // Check Date Fin
                if(this.form_dateFin) {
                    date_fin = new Date(this.form_dateFin);
                    if(date_fin instanceof Date && !isNaN(date_fin)){
                        console.log('check Date Fin OK : ' + formatDate(date_fin));
                    } else {
                        this.errors.push('Date de fin non valide');
                    }
                }
            }

            this.waiting = false;
            if(this.errors.length === 0) {
                let newId = await this.addNumero(this.form_prefixe, numero, id_usager, id_proprietaire, this.form_nomOperateur, formatDate(date_debut), formatDate(date_fin));
                bus.$emit('refreshDatatable');
                if(newId) alert('Numéro bien inséré avec l\'id: ' + newId);
                else this.errors.push('Erreur d\'insertion du numéro, veuillez vérifier que le numéro n\'est pas déjà utilisé à une date ultérieure');
            }
        }
    },
    mounted() {
        this.refreshListClients();
    }
}
</script>



<style scoped>
#form-container {
    margin: 1rem;
}

#form-container h3 {
    margin-top: 0;
    margin-bottom: 0.5rem;
}

.form-insert-numero {
    display: flex;
    flex-direction: column;
}

#error-list {
    color: rgb(252, 64, 31);
}

.required {
    font-weight: bold;
}
</style>