<template>
  <div id="numeros">
    <h1>Bienvenue Numeros</h1>
    <div id="main">
      <FormInsertNumero
        :jwt="this.jwt"
      >
      </FormInsertNumero>
      <DataTableAPI
        :jwt="this.jwt"
        :headerFields="this.headerFieldsNumeros"
        :apiUrl="this.apiUrlNumeros"
        :defaultSort="{sort: 'asc', sortField: 'id'}"
        :apiArgs="'&in_date=' + this.todayDate"
        :searchable="true"
      >
      </DataTableAPI>
    </div>
  </div>
</template>

<script>
import DataTableAPI from '@/components/DataTableAPI.vue'
import FormInsertNumero from '@/components/FormInsertNumero.vue'
import { formatDate } from '@/lib/date_func.js'


let headerFieldsNumeros = [
    {
        name: "id",
        label: "Id",
        sortable: true
    },
    {
        name: "prefixe",
        label: "Préfixe",
        sortable: true
    },
    {
        name: "numero",
        label: "Numéro",
        sortable: true
    },
    {
        name: "nom_usager",
        label: "Usager",
        sortable: true,
    },
    {
        name: "nom_proprietaire",
        label: "Propriétaire",
        sortable: true,
    },
    {
        name: "nom_operateur",
        label: "Opérateur",
        sortable: true,
    },
    {
      name: 'lien_numero',
      label: 'Détails',
      sortable: false,
      customElement: 'linkToItem',
      link: 'Numero'
    }
];

export default {
  name: 'Numeros',
  components: {
    DataTableAPI,
    FormInsertNumero
  },
  props: ['propJwt'],
  data: function () {
    return {
      headerFieldsNumeros: headerFieldsNumeros,
      apiUrlNumeros: this.$apiBaseUrl + 'rpc/func_searchnumerosdata_fromdate',
      jwt: this.$jwt_temp,
      todayDate: formatDate(new Date(), 'YYYYMMDD')
    }
  },
  methods: {
  },
  created() {
  }
}
</script>


<style>
</style>

<style scoped>
#main {
  display: flex;
  flex-wrap: wrap;
}
</style>