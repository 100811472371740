<template>
  <div id="imports">
    <h1>Bienvenue Imports</h1>
    <DataTableAPI
      :jwt="this.jwt"
      :headerFields="this.headerFieldsImports"
      :defaultSort="{sort: 'desc', sortField: 'run_id'}"
      :apiUrl="this.apiUrlImports"
      :searchable="true"
    >
    </DataTableAPI>
  </div>
</template>

<script>
import DataTableAPI from '@/components/DataTableAPI.vue'

const addZero = value => ("0" + value).slice(-2);

const formatDate = value => {
  if (value) {
    const dt = new Date(value);
    return `${addZero(dt.getDate())}-${addZero(
      dt.getMonth() + 1
    )}-${dt.getFullYear()}`;
  }
  return "";
}

let headerFieldsImports = [
    {
        name: 'run_id',
        label: 'Id d\'import',
        sortable: true
    },
    {
        name: 'date_import',
        label: 'Date d\'import',
        sortable: true,
        format: formatDate
    },
    {
        name: 'file_name', 
        label: 'Nom du fichier',
        sortable: true
    },
    {
        name: 'job',
        label: 'Job',
        sortable: true
    },
    {
        name: 'last_step',
        label: 'Étape',
        sortable: true
    }
];

export default {
  name: 'Imports',
  components: {
    DataTableAPI
  },
  data: function () {
    return {
      headerFieldsImports: headerFieldsImports,
      apiUrlImports: this.$apiBaseUrl + 'rpc/func_searchimport_allcolumn',
      jwt: this.$jwt_temp
    }
  },
  methods: {
  },
  created() {
  }
}
</script>

<style>
</style>
