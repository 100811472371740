
function addZero(value) {
    return ("0" + value).slice(-2);
}

function formatDate(value, format = 'DDMMYYYY') {
    if (!value) {
        return "";
    }
    if(format == 'DDMMYYYY') {
        const dt = new Date(value);
        return `${addZero(dt.getDate())}-${addZero(
        dt.getMonth() + 1
        )}-${dt.getFullYear()}`;
    } else if (format == 'YYYYMMDD') {
        const dt = new Date(value);
        return `${dt.getFullYear()}-${addZero(
        dt.getMonth() + 1
        )}-${addZero(dt.getDate())}`;
    }
}

export { addZero, formatDate }