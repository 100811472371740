import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views/Index.vue'
import Numeros from '../views/Numeros.vue'
import Numero from '../views/Numero.vue'
import Clients from '../views/Clients.vue'
import Imports from '../views/Imports.vue'
import Equivalences from '../views/Equivalences.vue'
import LexiqueCol from '../views/LexiqueCol.vue'
import NotFound from '../views/NotFound.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Index',
    component: Index
  },
  {
    path: '/numeros',
    name: 'Numeros',
    component: Numeros
  },
  {
    path: '/numero/:id',
    name: 'Numero',
    component: Numero
  },
  {
    path: '/clients',
    name: 'Clients',
    component: Clients
  },
  {
    path: '/imports',
    name: 'Imports',
    component: Imports
  },
  {
    path: '/equivalences',
    name: 'Equivalences',
    component: Equivalences
  },
  {
    path: '/lexiquecol',
    name: 'LexiqueCol',
    component: LexiqueCol
  },
  {
    path: '/:catchAll(.*)',
    component: NotFound
  }
]

const router = new VueRouter({
  //mode: 'history',
  routes
})

export default router
