<template>
    <div id="form-container">
        <h3>Insérer une catégorie</h3>
        <form @submit="submitForm" method="post" class="form-insert">
            <ul id="error-list" v-if="errors && errors.length > 0">
                <li v-for="error in errors" :key="error">{{ error }}</li>
            </ul>
            <span>
                <label for="nom_categorie">Nom catégorie : </label>
                <input v-model="form_categorie" type="text" name="nom_categorie" id="input_nom_categorie" required/>
            </span>
            <span>
                <input type="submit" id="submit" value="Valider">
                <p v-if="waiting">Waiting...</p>
            </span>
        </form>
    </div>
</template>

<script>
import axios from 'axios';
import { bus } from '../main';

export default {
    name: 'FormInsertDestinationCategorie',
    components: {
    },
    data: function() {
        return {
            form_categorie: '',
            errors: [],
            waiting: false,
            apiUrlInsert: this.$apiBaseUrl + 'rpc/func_insert_destinationcategorie'
        }
    },
    props: ['jwt'],
    methods: {
        addCategorie: async function(nom_categorie) {
            const URL = this.apiUrlInsert;
            const data = {
                in_categorie: nom_categorie
            };
            let id_new = false;
            await axios
            .post(URL, data, {
                headers: {
                    Authorization: "Bearer " + this.jwt,
                }
            })
            .then( res => {
                id_new = res.data;
            })
            .catch((e) => {
                console.log(e);
            });
            return id_new;
        },
        submitForm: async function(e) {
            e.preventDefault();
            this.waiting = true;
            this.errors = [];
            
            // Check nom catégorie
            if(this.form_categorie.length > 2){
                console.log('check nom catégorie OK: ' + this.form_categorie);
            } else {
                this.errors.push('Nom de catégorie trop court');
            }

            this.waiting = false;
            if(this.errors.length === 0) {
                let newId = await this.addCategorie(this.form_categorie);
                bus.$emit('refreshDatatable');
                if(newId) alert('Nouvelle catégorie bien inséré avec l\'id: ' + newId);
                else this.errors.push('Erreur d\'insertion');
            }
        }
    },
    mounted() {
    }
}
</script>



<style scoped>
#form-container {
    margin: 1rem;
}

#form-container h3 {
    margin-top: 0;
    margin-bottom: 0.5rem;
}

.form-insert {
    display: flex;
    flex-direction: column;
}

#error-list {
    color: rgb(252, 64, 31);
}
</style>