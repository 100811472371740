<template>
  <div id="numero">
    <h1>Bienvenue Numero</h1>
    <div id="detail-numero">
      <span>+{{this.prefixe}} {{this.numero}}</span>
    </div>
    <div id="numero-main">
      <div id="numero-usager">
        <Timeline
          :data="this.historyUsagers"
        ></Timeline>
        <FormInsertNumeroUsager
        :jwt="this.jwt"
        :id_numero="this.id_numero"
        />
        <DataTableAPI
          :jwt="this.jwt"
          :headerFields="this.headerFieldsUsager"
          :apiUrl="this.apiUrlUsager"
          :defaultSort="{sort: 'asc', sortField: 'id'}"
          :apiArgs="'&in_id_numero=' + this.id_numero"
        >
        </DataTableAPI>
      </div>
      <div id="numero-proprietaire">
        <Timeline
          :data="this.historyProprietaires"
        ></Timeline>
        <FormInsertNumeroProprietaire
          :jwt="this.jwt"
          :id_numero="this.id_numero"
        />
        <DataTableAPI
          :jwt="this.jwt"
          :headerFields="this.headerFieldsProprietaire"
          :apiUrl="this.apiUrlProprietaire"
          :defaultSort="{sort: 'asc', sortField: 'id'}"
          :apiArgs="'&in_id_numero=' + this.id_numero"
        >
      </DataTableAPI>
      </div>
      <div id="numero-operateur">
        <Timeline
          :data="this.historyOperateurs"
        ></Timeline>
        <FormInsertNumeroOperateur
          :jwt="this.jwt"
          :id_numero="this.id_numero"
        />
        <DataTableAPI
          :jwt="this.jwt"
          :headerFields="this.headerFieldsOperateur"
          :apiUrl="this.apiUrlOperateur"
          :defaultSort="{sort: 'asc', sortField: 'id'}"
          :apiArgs="'&in_id_numero=' + this.id_numero"
        >
        </DataTableAPI>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import DataTableAPI from '@/components/DataTableAPI.vue'
import FormInsertNumeroUsager from '@/components/FormInsertNumeroUsager.vue'
import FormInsertNumeroProprietaire from '@/components/FormInsertNumeroProprietaire.vue'
import FormInsertNumeroOperateur from '@/components/FormInsertNumeroOperateur.vue'
import Timeline from '@/components/Timeline.vue'
import { bus } from '../main';
import { formatDate } from '@/lib/date_func.js'

let headerFieldsUsager = [
  {
      name: "nom_usager",
      label: "Usager",
      sortable: true
  },
  {
      name: "date_debut",
      label: "Date début",
      sortable: true,
      format: formatDate
  },
  {
      name: "date_fin",
      label: "Date fin",
      sortable: true,
      format: formatDate
  }
]

let headerFieldsProprietaire = [
  {
      name: "nom_proprietaire",
      label: "Proprietaire",
      sortable: true
  },
  {
      name: "date_debut",
      label: "Date début",
      sortable: true,
      format: formatDate
  }
]

let headerFieldsOperateur = [
  {
      name: "nom_operateur",
      label: "Operateur",
      sortable: true
  },
  {
      name: "date_debut",
      label: "Date début",
      sortable: true,
      format: formatDate
  }
]

export default {
  name: 'Numero',
  components: {
    DataTableAPI,
    FormInsertNumeroUsager,
    FormInsertNumeroProprietaire,
    FormInsertNumeroOperateur,
    Timeline
  },
  data: function () {
    return {
      apiUrlNumero: this.$apiBaseUrl + 'numeros',
      headerFieldsUsager: headerFieldsUsager,
      apiUrlUsager: this.$apiBaseUrl + 'rpc/func_getnumerohistory_usager',
      historyUsagers: [],
      headerFieldsProprietaire: headerFieldsProprietaire,
      historyProprietaires: [],
      apiUrlProprietaire: this.$apiBaseUrl + 'rpc/func_getnumerohistory_proprietaire',
      headerFieldsOperateur: headerFieldsOperateur,
      apiUrlOperateur: this.$apiBaseUrl + 'rpc/func_getnumerohistory_operateur',
      historyOperateurs: [],
      id_numero: this.$route.params.id,
      numero: '',
      prefixe: '',
      jwt: this.$jwt_temp
    }
  },
  methods: {
    loadNumero: function() {
        const URL = this.apiUrlNumero + "?order=id.asc&id=eq." + this.id_numero;
        axios
        .get(URL, {
            headers: {
                Authorization: "Bearer " + this.jwt,
            }
        })
        .then( res => {
            this.prefixe = res.data[0].prefixe;
            this.numero = res.data[0].numero;
        })
        .catch((e) => console.log(e));
    },
    getHistoryUsager: function() {
        const URL = this.apiUrlUsager + "?order=date_debut.asc&in_id_numero=" + this.id_numero;
        axios
        .get(URL, {
            headers: {
                Authorization: "Bearer " + this.jwt,
            }
        })
        .then( res => {
          this.historyUsagers = [];
          res.data.forEach(elem => {
              this.historyUsagers.push({
                nom: elem.nom_usager,
                date_debut: new Date(elem.date_debut),
                date_fin: new Date(elem.date_fin)
              });
          })
        })
        .catch((e) => console.log(e));
    },
    getHistoryProprietaire: function() {
        const URL = this.apiUrlProprietaire + "?order=date_debut.asc&in_id_numero=" + this.id_numero;
        axios
        .get(URL, {
            headers: {
                Authorization: "Bearer " + this.jwt,
            }
        })
        .then( res => {
          this.historyProprietaires = [];
          res.data.forEach(elem => {
              this.historyProprietaires.push({
                nom: elem.nom_proprietaire,
                date_debut: new Date(elem.date_debut)
              });
          })
        })
        .catch((e) => console.log(e));
    },
    getHistoryOperateur: function() {
        const URL = this.apiUrlOperateur + "?order=date_debut.asc&in_id_numero=" + this.id_numero;
        axios
        .get(URL, {
            headers: {
                Authorization: "Bearer " + this.jwt,
            }
        })
        .then( res => {
          this.historyOperateurs = [];
          res.data.forEach(elem => {
              this.historyOperateurs.push({
                nom: elem.nom_operateur,
                date_debut: new Date(elem.date_debut)
              });
          })
        })
        .catch((e) => console.log(e));
    },
    refreshTimelines: function() {
      this.getHistoryUsager();
      this.getHistoryProprietaire();
      this.getHistoryOperateur();
    }
  },
  mounted() {
    this.loadNumero();
    this.refreshTimelines();
    bus.$on('refreshDatatable', this.refreshTimelines);
  },
  created() {
  }
}
</script>

<style scoped>
#numero-main {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

#numero-main > *:not(:last-child){
  margin-right: 30px;
}

#detail-numero {
  padding-bottom: 1em;

  font-size: 1.5rem;
}
</style>
