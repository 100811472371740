<template>
  <div id="index">
    <h1>Bienvenue Index</h1>
  </div>
</template>

<script>

export default {
  name: 'Index',
  components: {
  },
  data: function () {
    return {
    }
  },
  methods: {
  }
}
</script>

<style>
</style>
