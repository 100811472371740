<template>
  <div id="app">
    <header>
      <div id="nav">
        <router-link to="/">Index</router-link> |
        <router-link to="/numeros">Numeros</router-link> |
        <router-link to="/clients">Clients</router-link> |
        <router-link to="/imports">Imports</router-link> |
        <router-link to="/equivalences">Equivalences</router-link> |
        <router-link to="/lexiquecol">Lexique Col</router-link>
      </div>
    </header>
    <main>
      <router-view/>
    </main>
    <footer>
      2022 - Colombia
    </footer>
  </div>
</template>


<script>
import 'normalize.css'
import '@/assets/css/variable.css'

export default {
    name: 'App',
    components: {
    },
    data: function () {
        return {
            jwt: this.$jwt_temp,
        }
    },
    methods: {
    },
    mounted() {
    }
}
</script>



<style>
body{
  display: flex;
  justify-content: center;
}

#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 95%;

  font-family: Helvetica, Arial, sans-serif;
  color: var(--main-text-color);
}

#nav {
  padding: 20px;
}

#nav a {
  font-weight: bold;
  color: var(--datatable-interactive-color);
}

#nav a.router-link-exact-active {
  font-weight: normal;
  text-decoration: none;
  color: #828584;
}
#nav a.router-link-exact-active:hover {
  cursor: default;
}

#app > footer {
  margin-top: auto;
  padding: 10px;
  border-radius: 30px 30px 0 0;

  text-align: center;

  background-color: #7fa4d5;
}

.icon-info {
  max-width: 1.2rem;
  max-height: 1.2rem;
}
</style>
