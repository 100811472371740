<template>
  <div id="clients">
    <h1>Bienvenue Clients</h1>
    <p>Liste des clients connus, pour vérifier la présence le plus sûr est de regarder l'id dolibarr (dans l'url sur la page d'un tiers)</p>
    <FormInsertClient
      :jwt="this.jwt"
    >
    </FormInsertClient>
    <DataTableAPI
      :jwt="this.jwt"
      :headerFields="this.headerFieldsClients"
      :defaultSort="{sort: 'asc', sortField: 'id'}"
      :apiUrl="this.apiUrlClients"
    >
    </DataTableAPI>
  </div>
</template>

<script>
import DataTableAPI from '@/components/DataTableAPI.vue'
import FormInsertClient from '@/components/FormInsertClient.vue'

let headerFieldsClients = [
    {
        name: 'id',
        label: 'Id',
        sortable: true
    },
    {
        name: 'id_doli',
        label: 'Id Dolibarr',
        sortable: true
    },
    {
        name: 'code_client', 
        label: 'Code Client',
        sortable: true
    },
    {
        name: 'description',
        label: 'Description',
        sortable: true
    }
];

export default {
  name: 'Clients',
  components: {
    DataTableAPI,
    FormInsertClient
  },
  data: function () {
    return {
      headerFieldsClients: headerFieldsClients,
      apiUrlClients: this.$apiBaseUrl + 'clients',
      jwt: this.$jwt_temp
    }
  },
  methods: {
  },
  created() {
  }
}
</script>

<style>
</style>
