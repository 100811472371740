<template>
    <div id="form-container">
        <h3>Ré-importer :</h3>
        <form @submit="submitForm" method="post" class="form-insert">
            <ul id="error-list" v-if="errors && errors.length > 0">
                <li v-for="error in errors" :key="error">{{ error }}</li>
            </ul>
            <span>
                <label for="id_import">Id import : </label>
                <input v-model="form_idImport" type="number" name="id_import" id="input_id_import" required/>
            </span>
            <span>
                <input type="submit" id="submit" value="Valider">
                <p v-if="waiting">Waiting...</p>
            </span>
        </form>
    </div>
</template>

<script>
import axios from 'axios';
import { bus } from '../main';

export default {
    name: 'FormBadToGlobal',
    components: {
    },
    data: function() {
        return {
            form_idImport: '',
            errors: [],
            waiting: false,
            apiUrlBadToGlobal: this.$apiBaseUrl + 'rpc/func_load_bad_to_global'
        }
    },
    props: ['jwt'],
    methods: {
        runBadToGlobal: async function(id_import) {
            const URL = this.apiUrlBadToGlobal;
            const data = {
                in_run_id: id_import
            };
            await axios
            .post(URL, data, {
                headers: {
                    Authorization: "Bearer " + this.jwt,
                }
            })
            .then( () => {
                alert('Toutes les lignes non erronées sont importées, vérifier le tableau')
            })
            .catch((e) => {
                console.log(e);
                alert('Erreur de connexion');
            });
        },
        submitForm: async function(e) {
            e.preventDefault();
            this.waiting = true;
            this.errors = [];
            
            // Check id import
            let id_import = parseInt(this.form_idImport)
            if(!id_import){
                this.errors.push('Id d\'import incorrect');
            }

            this.waiting = false;
            if(this.errors.length === 0) {
                await this.runBadToGlobal(id_import);
                bus.$emit('refreshDatatable');
            }
        }
    },
    mounted() {
    }
}
</script>



<style scoped>
#form-container {
    margin: 1rem;
}

#form-container h3 {
    margin-top: 0;
    margin-bottom: 0.5rem;
}

.form-insert {
    display: flex;
    flex-direction: column;
}

#error-list {
    color: rgb(252, 64, 31);
}
</style>