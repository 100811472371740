<template>
    <div class="timeline-container">
        <div class="timeline-bar"></div>
        <ul>
            <li class="timeline-element" v-for="object of data" :key="object.nom">
                <span class="start-date"
                    v-if="object.date_debut && object.date_debut >= date_debut_annee && object.date_debut <= date_fin_annee"
                    :style="`left: ${percentOfYear(object.date_debut)}%;`"
                >
                    <span class="cursor"></span>
                    <span class="date-text">{{formatDate(object.date_debut)}}</span>
                    <span class="data-text">{{object.nom}}</span>
                </span>
                <span class="end-date"
                    v-if="object.date_fin && object.date_fin >= date_debut_annee && object.date_fin <= date_fin_annee"
                    :style="`left: ${percentOfYear(object.date_fin)}%;`">
                    <span class="cursor"></span>
                </span>
            </li>
        </ul>
    </div>
</template>


<script>
import { formatDate } from '@/lib/date_func.js'

export default {
    name: 'Timeline',
    components: {
    },
    data: function() {
        return {
            // Set in mounted()
            date_actuelle: undefined,
            date_debut_annee: undefined,
            date_fin_annee: undefined
        }
    },
    props: {
        /**
         * Data Array format
         * [
         *   {
         *     nom: String,
         *     date_debut: Date,
         *     date_fin: Date     // Optionnel
         *   },
         *   { ... }, ...
         * ]
         */
        data: Array
    },
    methods: {
        formatDate: formatDate,
        percentOfYear(date) {
            let timePassedFromStart = date - this.date_debut_annee;
            let yearLength = (this.date_fin_annee - this.date_debut_annee);
            return ((timePassedFromStart / yearLength) * 100);
        }
    },
    mounted() {
        this.date_actuelle = new Date();
        this.date_debut_annee = new Date(this.date_actuelle.getFullYear(), 0, 1);
        this.date_fin_annee = new Date(this.date_actuelle.getFullYear(), 11, 31);
    }
}
</script>


<style scoped>
/* Container */
.timeline-container {
    position: relative;
    width: 100%;
    height: 3rem;
}

.timeline-container > ul {
    margin: 0;
    padding: 0;
    height: 100%;
}

.timeline-container > ul > li {
    list-style: none;
}

/* Timeline bar */

.timeline-bar {
    position: absolute;
    top: 50%;
    width: 100%;

    border-top: 2px solid #337ab7;
    border-bottom: 2px solid #337ab7;

}

/* Timeline elements */
.timeline-element {
    font-size: 0.8rem;
}

.timeline-element .date-text{
    position: absolute;
    top: -2rem;
    width: 80px;
    transform: translateX(-10%) rotateZ(-30deg);
}

.timeline-element .data-text{
    position: absolute;
    top: 1.5rem;
    width: 80px;
}

.timeline-element span.start-date, .timeline-element span.end-date {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.timeline-element span.start-date {
    transform: translateY(-50%);
}
.timeline-element span.end-date {
    transform: translateY(-50%) translateX(-100%);
}

.timeline-container > ul > .timeline-element:nth-child(2n) {
    color: red;
}

.timeline-element span.start-date .cursor {
    border-right: 3px solid;
    border-radius: 1rem;
}

.timeline-element span.end-date .cursor {
    border-left: 3px solid;
    border-radius: 1rem;
}

.cursor {
    width: 1px;
}
</style>