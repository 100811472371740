<template>
  <div id="lexique-col">
    <h1>Bienvenue Lexique</h1>
    <p>
      Lexique des destinations, catégories et services utilisés par Colombia. <br>
      N'en ajouter que si aucune valeur ne corresponds.
    </p>
    <label for="showForms">Afficher formulaires : </label>
    <input type="checkbox" v-model="showForms" name="showForms" id="checkbox-showforms"><br><br>
    <div id="lexique-main">
      <div id="destination">
        <DataTableAPI
          :jwt="this.jwt"
          :headerFields="this.headerFieldsDestination"
          :apiUrl="this.$apiBaseUrl + 'destinations'"
          :defaultSort="{sort: 'asc', sortField: 'id'}"
        >
        </DataTableAPI>
      </div>
      <div id="destination-categorie">
        <FormInsertDestinationCategorie
          v-if="this.showForms"
          :jwt="this.jwt"
        ></FormInsertDestinationCategorie>
        <DataTableAPI
          :jwt="this.jwt"
          :headerFields="this.headerFieldsDestinationCategorie"
          :apiUrl="this.$apiBaseUrl + 'destinationcategories'"
          :defaultSort="{sort: 'asc', sortField: 'id'}"
        >
      </DataTableAPI>
      </div>
      <div id="service">
        <FormInsertService
          v-if="this.showForms"
          :jwt="this.jwt"
        ></FormInsertService>
        <DataTableAPI
          :jwt="this.jwt"
          :headerFields="this.headerFieldsService"
          :apiUrl="this.$apiBaseUrl + 'services'"
          :defaultSort="{sort: 'asc', sortField: 'id'}"
        >
        </DataTableAPI>
      </div>
      </div>
  </div>
</template>

<script>
import DataTableAPI from '@/components/DataTableAPI.vue'
import FormInsertDestinationCategorie from '@/components/FormInsertDestinationCategorie.vue'
import FormInsertService from '@/components/FormInsertService.vue'

let headerFieldsDestination = [
  {
    name: 'indicatif',
    label: 'Indicatif',
    sortable: true
  },
  {
    name: 'dest_nom',
    label: 'Destination',
    sortable: true
  }
];

let headerFieldsDestinationCategorie = [
  {
    name: 'categorie',
    label: 'Categorie de destination',
    sortable: true
  }
];

let headerFieldsService = [
  {
    name: 'service',
    label: 'Service',
    sortable: true
  }
];

export default {
  name: 'Equivalences',
  components: {
    DataTableAPI,
    FormInsertDestinationCategorie,
    FormInsertService
  },
  data: function () {
    return {
      jwt: this.$jwt_temp,
      headerFieldsDestination: headerFieldsDestination,
      headerFieldsDestinationCategorie: headerFieldsDestinationCategorie,
      headerFieldsService: headerFieldsService,
      showForms: false
    }
  },
  methods: {
  },
  created() {
  }
}
</script>

<style scoped>
#lexique-main {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

#lexique-main > *:not(:last-child){
  margin-right: 30px;
}
</style>
