<template>
    <div id="form-container">
        <h3>Insérer un opérateur</h3>
        <form @submit="submitForm" method="post" class="form-insert">
            <ul id="error-list" v-if="errors && errors.length > 0">
                <li v-for="error in errors" :key="error">{{ error }}</li>
            </ul>
            <span>
                <label for="nom_operateur">Nom operateur : </label>
                <input v-model="form_nomOperateur" type="text" name="nom_operateur" id="input_nom_operateur" required/>
            </span>
            <span>
                <label for="date_debut">Date de début : </label>
                <input v-model="form_dateDebut" type="date" name="date_debut" id="input_date_debut">
            </span>
            <span>
                <input type="submit" id="submit" value="Valider">
                <p v-if="waiting">Waiting...</p>
            </span>
        </form>
    </div>
</template>

<script>
import axios from 'axios';
import { bus } from '../main';
import { formatDate } from '@/lib/date_func.js'

export default {
    name: 'FormInsertNumeroOperateur',
    components: {
    },
    data: function() {
        return {
            form_nomOperateur: '',
            form_dateDebut: '',
            errors: [],
            waiting: false,
            apiUrlInsert: this.$apiBaseUrl + 'rpc/func_insert_operateur'
        }
    },
    props: ['jwt', 'id_numero'],
    methods: {
        addOperateur: async function(nom_operateur, date_debut) {
            const URL = this.apiUrlInsert;
            const data = {
                in_id_numero: parseInt(this.id_numero),
                in_nom_operateur: nom_operateur,
                in_date_debut: date_debut
            };
            let id_new = false;
            await axios
            .post(URL, data, {
                headers: {
                    Authorization: "Bearer " + this.jwt,
                }
            })
            .then( res => {
                id_new = res.data;
            })
            .catch((e) => {
                console.log(e);
            });
            return id_new;
        },
        submitForm: async function(e) {
            e.preventDefault();
            this.waiting = true;
            this.errors = [];
            
            // Check nom opérateur
            if(this.form_nomOperateur.length > 2){
                console.log('check nom opérateur OK: ' + this.form_nomOperateur);
            } else {
                this.errors.push('Nom d\'opérateur trop court');
            }
            // Check Date Debut
            let date_debut = new Date(this.form_dateDebut);
            if(date_debut instanceof Date && !isNaN(date_debut)){
                console.log('check Date Debut OK : ' + formatDate(date_debut));
            } else {
                this.errors.push('Date de début non valide');
            }

            this.waiting = false;
            if(this.errors.length === 0) {
                let newId = await this.addOperateur(this.form_nomOperateur, formatDate(date_debut));
                bus.$emit('refreshDatatable');
                if(newId) alert('Nouvel opérateur bien inséré avec l\'id: ' + newId);
                else this.errors.push('Erreur d\'insertion, veuillez vérifier que la date n\'est pas déjà utilisée');
            }
        }
    },
    mounted() {
    }
}
</script>



<style scoped>
#form-container {
    margin: 1rem;
}

#form-container h3 {
    margin-top: 0;
    margin-bottom: 0.5rem;
}

.form-insert {
    display: flex;
    flex-direction: column;
}

#error-list {
    color: rgb(252, 64, 31);
}
</style>