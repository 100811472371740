import Vue from 'vue'
import App from './App.vue'
import router from './router'

Vue.config.productionTip = false

export const bus = new Vue();

// BDD Prod
Vue.prototype.$apiBaseUrl = 'http://192.168.30.33:3000/'
Vue.prototype.$jwt_temp = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb2xlIjoiYXBpX3N1cGVyYWRtaW4iLCJleHAiOjE4NDkxNjk4Njd9.ZR8W-GgpTM2Otf6S35qIn92T1JrbbCYm2xw-nb0r778'

// BDD Dev
// Vue.prototype.$apiBaseUrl = 'http://localhost:3000/'
// Vue.prototype.$jwt_temp = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb2xlIjoiYXBpX3N1cGVyYWRtaW4iLCJleHAiOjE2NTkwMDc3NDJ9.l5zCAAZYbwGvg2zTaVP4LZyTS3In_CPERMjM5i8F8UE'


new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
