<template>
    <div id="form-container">
        <h3>Insérer une équivalence</h3>
        <select v-model="form_selected" id="form-selector">
            <option value="destination">Destination</option>
            <option value="destination_categorie">Catégorie de destination</option>
            <option value="service">Service</option>
        </select>
        <ul id="error-list" v-if="errors && errors.length > 0">
            <li v-for="error in errors" :key="error">{{ error }}</li>
        </ul>
        <form v-if="form_selected == 'destination'" @submit="submitFormDestinationEqu" method="post" class="form-insert-destination">
            <span>
                <label for="destination">Nom destination : </label>
                <input v-model="form_destination" type="text" name="destination" id="input_destination" required/>
            </span>
            <span>
                <label for="destination_equ">Lié à : </label>
                <select v-model="form_destination_equ" name="destination_equ" id="input_destination_equ" required>
                    <option v-for="destination in destinations" :key="destination.id" :value="destination.id">{{ destination.dest_nom }}</option>
                </select>
            </span>
            <span>
                <input type="submit" id="submit" value="Valider">
                <p v-if="waiting">Waiting...</p>
            </span>
        </form>
        <form v-if="form_selected == 'destination_categorie'" @submit="submitFormDestinationCategorieEqu" method="post" class="form-insert-destination-categorie">
            <span>
                <label for="destination_categorie">Nom catégorie : </label>
                <input v-model="form_destinationCategorie" type="text" name="destination_categorie" id="input_destination_categorie" required/>
            </span>
            <span>
                <label for="destination_categorie_equ">Lié à : </label>
                <select v-model="form_destinationCategorie_equ" name="destination_categorie_equ" id="input_destination_categorie_equ" required>
                    <option v-for="destinationCategorie in destinationCategories" :key="destinationCategorie.id" :value="destinationCategorie.id">{{ destinationCategorie.categorie }}</option>
                </select>
            </span>
            <span>
                <input type="submit" id="submit" value="Valider">
                <p v-if="waiting">Waiting...</p>
            </span>
        </form>
        <form v-if="form_selected == 'service'" @submit="submitFormServiceEqu" method="post" class="form-insert-service">
            <span>
                <label for="service">Nom service : </label>
                <input v-model="form_service" type="text" name="service" id="input_service" required/>
            </span>
            <span>
                <label for="service_equ">Lié à : </label>
                <select v-model="form_service_equ" name="service_equ" id="input_service_equ" required>
                    <option v-for="service in services" :key="service.id" :value="service.id">{{ service.service }}</option>
                </select>
            </span>
            <span>
                <input type="submit" id="submit" value="Valider">
                <p v-if="waiting">Waiting...</p>
            </span>
        </form>
    </div>
</template>

<script>
import axios from 'axios';
import { bus } from '../main';

export default {
    name: 'FormInsertEqu',
    components: {
    },
    data: function() {
        return {
            form_selected: 'destination',
            form_destination: '',
            form_destination_equ: '',
            form_destinationCategorie: '',
            form_destinationCategorie_equ: '',
            form_service: '',
            form_service_equ: '',
            errors: [],
            waiting: false,
            destinations: [],
            destinationCategories: [],
            services: [],
            apiUrlDestination: this.$apiBaseUrl + 'destinations',
            apiUrlDestinationCategorie: this.$apiBaseUrl + 'destinationcategories',
            apiUrlService: this.$apiBaseUrl + 'services',
            apiUrlInsertDestinationEqu: this.$apiBaseUrl + 'rpc/func_insert_equ_dest',
            apiUrlInsertDestinationCategorieEqu: this.$apiBaseUrl + 'rpc/func_insert_equ_dest_categorie',
            apiUrlInsertServiceEqu: this.$apiBaseUrl + 'rpc/func_insert_equ_service'
        }
    },
    props: ['jwt'],
    methods: {
        refreshListDestinations: function() {
            const URL = this.apiUrlDestination + "?order=dest_nom.asc" + "&select=id,dest_nom";
            axios
            .get(URL, {
                headers: {
                    Authorization: "Bearer " + this.jwt,
                }
            })
            .then( res => {
                this.destinations = res.data;
            })
            .catch((e) => console.log(e))
        },
        refreshListDestinationCategories: function() {
            const URL = this.apiUrlDestinationCategorie + "?order=categorie.asc" + "&select=id,categorie";
            axios
            .get(URL, {
                headers: {
                    Authorization: "Bearer " + this.jwt,
                }
            })
            .then( res => {
                this.destinationCategories = res.data;
            })
            .catch((e) => console.log(e))
        },
        refreshListServices: function() {
            const URL = this.apiUrlService + "?order=service.asc" + "&select=id,service";
            axios
            .get(URL, {
                headers: {
                    Authorization: "Bearer " + this.jwt,
                }
            })
            .then( res => {
                this.services = res.data;
            })
            .catch((e) => console.log(e))
        },
        addDestinationEqu: async function(nom_destination, id_equ) {
            const URL = this.apiUrlInsertDestinationEqu;
            const data = {
                in_nom_destination: nom_destination,
                in_equ: parseInt(id_equ)
            };
            let id_new = false;
            await axios
            .post(URL, data, {
                headers: {
                    Authorization: "Bearer " + this.jwt,
                }
            })
            .then( res => {
                id_new = res.data;
            })
            .catch((e) => {
                console.log(e);
            });
            this.form_destination = '';
            this.form_destination_equ = '';
            return id_new;
        },
        addDestinationCategorieEqu: async function(nom_categorie, equ) {
            const URL = this.apiUrlInsertDestinationCategorieEqu;
            const data = {
                in_nom_categorie: nom_categorie,
                in_equ: parseInt(equ)
            };
            let id_new = false;
            await axios
            .post(URL, data, {
                headers: {
                    Authorization: "Bearer " + this.jwt,
                }
            })
            .then( res => {
                id_new = res.data;
            })
            .catch((e) => {
                console.log(e);
            });
            this.form_destinationCategorie = '';
            this.form_destinationCategorie_equ = '';
            return id_new;
        },
        addServiceEqu: async function(nom_service, equ) {
            const URL = this.apiUrlInsertServiceEqu;
            const data = {
                in_nom_service: nom_service,
                in_equ: parseInt(equ)
            };
            let id_new = false;
            await axios
            .post(URL, data, {
                headers: {
                    Authorization: "Bearer " + this.jwt,
                }
            })
            .then( res => {
                id_new = res.data;
            })
            .catch((e) => {
                console.log(e);
            });
            this.form_service = '';
            this.form_service_equ = '';
            return id_new;
        },
        submitFormDestinationEqu: function(e) {
            e.preventDefault();
            this.addDestinationEqu(this.form_destination, this.form_destination_equ);
            bus.$emit('refreshDatatable');
        },
        submitFormDestinationCategorieEqu: async function(e) {
            e.preventDefault();
            this.addDestinationCategorieEqu(this.form_destinationCategorie, this.form_destinationCategorie_equ);
            bus.$emit('refreshDatatable');
        },
        submitFormServiceEqu: async function(e) {
            e.preventDefault();
            this.addServiceEqu(this.form_service, this.form_service_equ);
            bus.$emit('refreshDatatable');
        }
    },
    mounted() {
        this.refreshListDestinations();
        this.refreshListDestinationCategories();
        this.refreshListServices();
    }
}
</script>



<style scoped>
#form-container {
    margin: 1rem;
}

#form-container form {
    display: flex;
    flex-direction: column;
}

#form-container h3 {
    margin-top: 0;
    margin-bottom: 0.5rem;
}

.form-insert {
    display: flex;
    flex-direction: column;
}

#error-list {
    color: rgb(252, 64, 31);
}
</style>